<template>
  <v-row class="campaigns">
    <v-col cols="12" class="pa-0">
      <div class="title justify-space-between">
        <div class="text-sm-h5 text-h6 font-weight-bold">
          <router-link :to="`/campaigns/${$route.params.campaignId}`" class="white--text">
            <v-icon class="white--text text-sm-h4 text-h5 mb-1">mdi-chevron-left</v-icon>Group details
          </router-link>
        </div>
        <div class="d-flex title-input">
          <v-text-field
            v-model="search"
            placeholder="Search by name or email"
            prepend-inner-icon="mdi-magnify"
            :append-icon="search ? 'mdi-close' : ''"
            @click:append="clearSearch"
            outlined
            dense
            required
            color="input"
            @change="(page = 1), getData()"
          >
          </v-text-field>
        </div>
      </div>
      <v-card
        v-if="load"
        tile
        elevation="5"
        max-width="1070"
        height="70vh"
        min-height="250"
        class="mx-lg-auto mx-4 mx-sm-15 pa-3 d-flex align-center justify-center flex-column"
      >
        <v-img max-height="80" contain src="@/assets/icon/loader.gif"></v-img>
        <div class="text-body-2 opasity--text">Loading...</div>
      </v-card>
      <v-card v-else elevation="5" max-width="1070" class="mx-lg-auto mx-4 mx-sm-15">
        <v-card flat height="70vh" min-height="260" v-if="nothing" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Sorry, but nothing found</div>
        </v-card>
        <v-card flat height="70vh" min-height="260" v-else-if="block" class="pa-3 d-flex align-center justify-center flex-column">
          <v-img max-height="124" contain src="@/assets/img/notFound.svg"></v-img>
          <div class="text-center text-h5 font-weight-bold mt-8">Looks like there are not prices for your account.</div>
          <div class="text-center opasity--text font-weight-regular mt-2">Please, contact support to resolve this issue.</div>
        </v-card>
        <div v-else>
          <v-data-table
            hide-default-footer
            fixed-header
            :headers="headers"
            :items="data.result"
            item-key="id"
            :items-per-page="Number(itemsPerPage)"
            :height="`calc(100vh - 230px)`"
            class="d-sm-block d-none p-table"
          >
            <template v-slot:footer>
              <v-divider></v-divider>
              <v-row class="mt-2 pb-2 px-10" align="center" justify="end">
                <span class="grey--text">Rows per page:</span>
                <div class="perPageSelect">
                  <v-select
                    v-model="itemsPerPage"
                    :items="itemsPerPageArray"
                    menu-props="auto"
                    hide-details
                    single-line
                    dense
                    @change="getData"
                  ></v-select>
                </div>
                <div>
                  <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <span class="mx-4 grey--text">{{ page }} of {{ data.pages }}</span>
                  <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </v-row>
            </template>
          </v-data-table>
          <div class="d-block d-sm-none">
            <div v-for="item in data.result" :key="item.id" class="px-4 pt-2">
              <div v-for="head in headers" :key="head.value" class="d-flex my-1 link" @click="openItem(item.id)">
                <div class="opasity--text f18 font-weight-medium label-table">{{ head.text }}</div>
                <div class="font-weight-medium f18">{{ item[head.value] }}</div>
              </div>
              <v-divider></v-divider>
            </div>
            <v-row class="my-2 pb-2" align="center" justify="center">
              <span class="grey--text">Rows per page:</span>
              <div class="perPageSelect">
                <v-select
                  v-model="itemsPerPage"
                  :items="itemsPerPageArray"
                  menu-props="auto"
                  hide-details
                  single-line
                  dense
                  @change="getData"
                ></v-select>
              </div>
              <div>
                <v-btn icon color="secondary" class="mr-1" @click="formerPage">
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <span class="mx-3 mt-1 grey--text">{{ page }} of {{ data.pages }}</span>
                <v-btn icon color="secondary" class="ml-1" @click="nextPage">
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      load: true,
      search: this.$route.query.src || '',
      itemsPerPage: Number(this.$route.query.page_size) || 10,
      page: Number(this.$route.query.page) || 1,
      block: false,
      nothing: true,
      headers: [
        { text: 'Name ', value: 'first_name', sortable: false },
        { text: 'Last Name ', value: 'last_name', sortable: false },
        { text: 'Phone', value: 'phone', sortable: false },
      ],
      statusError: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    openItem(id) {
      this.$router.push({ name: 'Campaign', params: { campaignId: id } });
    },
    async getData() {
      await this.$store
        .dispatch('getCampaignList', {
          pageSize: this.itemsPerPage,
          search: this.search,
          page: this.page,
        })
        .then(() => {
          this.nothing = false;
          this.block = false;
        })
        .catch((e) => {
          this.getDataError(e);
        })
        .finally(() => {
          this.load = false;
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'account__not_found') {
        this.$store.dispatch('setCampaignList', {});
        this.block = true;
      } else if (e.response.data.error[0] === 'result__not_found') {
        this.$store.dispatch('setCampaignList', {});
        this.nothing = true;
      } else if (e.response.data.error[0] === 'src__invalid') {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.campaignList;
    },
    itemsPerPageArray() {
      return this.$store.getters.pagination;
    },
  },
  destroyed() {
    this.$store.dispatch('setCampaignList', { send_option: {} });
  },
};
</script>
